import React from 'react'
import { Helmet } from 'react-helmet'

function Head() {
  return (
    <Helmet>
      <title>manga House</title>
      <meta name="description" content="This is the cooperate site of manga House" />
    </Helmet>
  )
}

export default Head